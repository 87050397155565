// MENU
$(document).ready(function () {
    $(".navbar__menu").on("click", function () {
        $(".menu-overlay").toggleClass("open-menu");
        $(this).toggleClass("icon-active");
        $("body").toggleClass("remove-body-scroll");
    });
});

function redirectWP($url) {
    window.location.href = $url;
}

// TOP
$(document).ready(function () {

    if ($(window).width() >= 620) {
        if ($(".mv").length) {
            $(window).on("scroll", function () {

                //verify element is visible in viewport
                var observerMV = new IntersectionObserver(function (entries) {
                    if (entries[0].isIntersecting === true) {
                        // console.log('visible');
                        $("#header").css("display", "none");

                    } else {
                        // console.log('not visible');
                        $("#header").css("display", "flex");
                    }
                }, { threshold: [0] });
                observerMV.observe(document.querySelector(".mv"));
            });

        } else {
            $("#header").css("display", "flex");
        }
    }

    //news tabs
    $(".item-topnews").on("click", function () {
        var id = $(this).data("tab");
        $(".item-topnews").removeClass("tab-active");
        $(".tab-topnews").removeClass("tab-topnews-active");
        $("#topnews-" + id).addClass("tab-active");
        $("#content-topnews-" + id).addClass("tab-topnews-active");
    });

    if ($(".mv").length) {
        $("body").on("touchstart click", function () {
            $("#topvideo").get(0).play();
        });

        var videoObserver = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting === true) {
                // console.log('visible');
                $("#topvideo").get(0).play();
            } else {
                // console.log('not visible');
                $("#topvideo").get(0).pause();
            }
        }, { threshold: [0] });
        videoObserver.observe(document.querySelector(".mv__container"));

        var textObserver = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting === true) {
                // console.log('visible');
                var elemPos = $(this).offset().top - 50;
                var scroll = $(window).scrollTop();
                var windowHeight = $(window).height();
                if (scroll >= elemPos - windowHeight) {
                    //左から右へ表示するクラスを付与
                    //テキスト要素を挟む親要素（左側）とテキスト要素を元位置でアニメーションをおこなう
                    $(".slide-in").addClass("slideAnimeLeftRight"); //要素を左枠外にへ移動しCSSアニメーションで左から元の位置に移動
                    $(".slide-in").children(".leftAnimeInner").addClass("slideAnimeRightLeft");  //子要素は親要素のアニメーションに影響されないように逆の指定をし元の位置をキープするアニメーションをおこなう
                }
            } else {
                // console.log('not visible');
            }
        }, { threshold: [0] });
        textObserver.observe(document.querySelector(".mv__container"));
    }


});

// PRIVACY POLICY
$(document).ready(function () {
    //tabs
    $(".tab-item").on("click", function () {
        var id = $(this).data("tab");
        $(".tab-item").removeClass("active-tab");
        $(".tab-pp").removeClass("tab-pp-active");
        $("#pp-" + id).addClass("active-tab");
        $("#content-pp-" + id).addClass("tab-pp-active");
    });
});

// CONTACT FORM
$(document).ready(function () {

    //form validation
    validateContact();
    $('.contact-form').show();
    $('.contact-confirm').hide();
    $('#contact-sendBtn').on('click', function (event) {
        if ($('#contact-form').valid()) {
            event.preventDefault();
            $('.desc-form').hide();
            $('.desc-confirm').show();
            $('.contact-form').hide();
            $('.contact-confirm').css('display', 'flex');
            $('.step1').removeClass('active-step');
            $('.step2').addClass('active-step');
            setValuesConfirmPage();
            window.scrollTo(0, 0);
        }
    });

    $('#backFormBtn').on('click', function (event) {
        event.preventDefault();
        $('.contact-form').show();
        $('.contact-confirm').hide();
        window.scrollTo(0, 0);
    });
});

$.validator.addMethod("nihongo", function (value) {
    //regEx = '/[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+[々〆〤]+/u';
    regEx = /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[々〆〤]+/;
    if (!regEx.test(value))
        return false;
    else if (regEx.test(value))
        return true;
}, '');


$.validator.addMethod("furigana", function (value) {
    //regEx = '/[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+[々〆〤]+/u';
    regEx = /[ぁ-んァ-ン]/;
    if (!regEx.test(value))
        return false;
    else if (regEx.test(value))
        return true;
}, '');


function validateContact() {
    $('#contact-form').validate({
        rules: {
            contacttype: {
                required: true
            },
            contactname: {
                required: true
            },
            contactfurigana: {
                required: true
            },
            contactemail: {
                required: true
            },
            contactphone: {
                required: true
            },
            contactagreement: {
                required: true
            }

        },
        messages: {
            contacttype: {
                required: "※未入力です。"
            },
            contactname: {
                required: "※未入力です。"
            },
            contactfurigana: {
                required: "※未入力です。"

            },
            contactemail: {
                required: "※未入力です。"
            },
            contactphone: {
                required: "※未入力です。"
            },
            contactagreement: {
                required: "※未入力です。"
            }
        },
        errorPlacement: function (error, element) {
            if (element.is(":radio")) {
                // if ($(window).width() <= 800) {
                //     error.appendTo(element.parents('.content').siblings('.showErrorRB-mobile'));
                // } else {
                error.appendTo(element.parents('.showErrorRB'));
                // }
            } else if (element.is(":checkbox")) {
                error.appendTo(element.parents('.showErrorCB'));
            }
            else { // This is the default behavior
                if (element.is("input")) {
                    // if ($(window).width() <= 800) {
                    //     error.appendTo(element.parents('.content').siblings('.showErrorInput-mobile'));
                    // } else {
                    error.appendTo(element.parents('.showErrorInput'));
                    // }

                } else if (element.is("textarea")) {
                    // if ($(window).width() <= 800) {
                    //     error.appendTo(element.parents('.content').siblings('.showErrorInput-mobile'));
                    // } else {
                    error.appendTo(element.parents('.showErrorInput'));
                    // }
                }
            }
        }
    });
}

function setValuesConfirmPage() {

    $("#rbTypeContact").text($("input[name='contacttype']:checked").val());
    $("#nameValue").text($("#contact-name").val());
    $("#furiganaValue").text($("#contact-furigana").val());
    $("#companyValue").text($("#contact-company").val());
    $("#emailValue").text($("#contact-email").val());
    $("#phoneValue").text($("#contact-phone").val());
    $("#contentValue").text($("#contact-content").val());

}

// FAQ 
$(document).ready(function () {
    $(".item-faq").on("click", function () {
        console.log("tab-faq");
        var id = $(this).data("tab");
        $(".item-faq").removeClass("tab-active");
        $(".tab-faq").removeClass("tab-faq-active");
        $("#faq-" + id).addClass("tab-active");
        $("#content-faq-" + id).addClass("tab-faq-active");
    });
});


// MOVIE
$(document).ready(function () {
    if ($('.movie__youtube').length) {

        $('.movie__youtube').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false,
            iframe: {
                markup: '<div class="mfp-iframe-scaler">' +
                    '<div class="mfp-close"></div>' +
                    '<iframe class="mfp-iframe" frameborder="0" allowfullscreen ></iframe>' +
                    '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
                patterns: {
                    youtube: {
                        index: 'youtube.com',
                        id: 'v=',
                        src: 'https://www.youtube.com/embed/%id%?'
                    }
                }
            },
        });
    }

});


// RECRUIT
$(document).ready(function () {
    if ($("#topimg1").length) {
        var topimg1Observer = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting === true) {
                // console.log('visible');
                $("#topimg1").addClass("zoomIn")
            } else {
                // console.log('not visible');
            }
        }, { threshold: [0.5] });
        topimg1Observer.observe(document.querySelector("#topimg1"));
    }

    if ($("#topimg2").length) {
        var topimg2Observer = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting === true) {
                // console.log('visible');
                $("#topimg2").addClass("zoomIn")
            } else {
                // console.log('not visible');
            }
        }, { threshold: [0.5] });
        topimg2Observer.observe(document.querySelector("#topimg2"))
    }

    if ($("#topimg3").length) {
        var topimg3Observer = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting === true) {
                // console.log('visible');
                $("#topimg3").addClass("zoomIn")
            } else {
                // console.log('not visible');
            }
        }, { threshold: [0.5] });
        topimg3Observer.observe(document.querySelector("#topimg3"));
    }

    $('.top-slider').slick({
        autoplay: true,         //自動再生
        autoplaySpeed: 0,       //自動再生のスピード
        speed: 6000,            //スライドスピード
        slidesToShow: 3,        //スライドの表示枚数
        cssEase: "linear",      //スライドの動きを等速に
        pauseOnHover: false,    //ホバーしても止まらないように
        pauseOnFocus: false,
        arrows: false,
        variableWidth: true
    });

    $(".item-appreq").on("click", function () {
        var id = $(this).data("tab");
        $(".item-appreq").removeClass("tab-active");
        $(".tab-appreq").removeClass("tab-appreq-active");
        $(".appreq-flow").removeClass("flow-active");
        $("#appreq-" + id).addClass("tab-active");
        $("#content-appreq-" + id).addClass("tab-appreq-active");
        $("#flow-appreq-" + id).addClass("flow-active");
    });

    var param = (new URL(location.href)).searchParams.get('tab');
    if (param === "newgraduated") {
        var id = "tab1";
        $(".item-appreq").removeClass("tab-active");
        $(".tab-appreq").removeClass("tab-appreq-active");
        $(".appreq-flow").removeClass("flow-active");
        $("#appreq-" + id).addClass("tab-active");
        $("#content-appreq-" + id).addClass("tab-appreq-active");
        $("#flow-appreq-" + id).addClass("flow-active");
    }

    if (param === "career") {
        var id = "tab2";
        $(".item-appreq").removeClass("tab-active");
        $(".tab-appreq").removeClass("tab-appreq-active");
        $(".appreq-flow").removeClass("flow-active");
        $("#appreq-" + id).addClass("tab-active");
        $("#content-appreq-" + id).addClass("tab-appreq-active");
        $("#flow-appreq-" + id).addClass("flow-active");
    }

    if (param === "parttime") {
        var id = "tab3";
        $(".item-appreq").removeClass("tab-active");
        $(".tab-appreq").removeClass("tab-appreq-active");
        $(".appreq-flow").removeClass("flow-active");
        $("#appreq-" + id).addClass("tab-active");
        $("#content-appreq-" + id).addClass("tab-appreq-active");
        $("#flow-appreq-" + id).addClass("flow-active");
    }

    if ($(".mv-recruit").length) {
        if ($(window).width() > 800) {
            var mvRecruitObserver = new IntersectionObserver(function (entries) {
                if (entries[0].isIntersecting === true) {
                    console.log('visible');
                    $("#recruittop-btn").hide();
                } else {
                    console.log('not visible');
                    $("#recruittop-btn").show();

                }
            }, { threshold: [0.95] });
            mvRecruitObserver.observe(document.querySelector(".mv-recruit"));
        }
    }

}); 